// Vanilla Libraries
import 'babel-polyfill';
import 'css.escape';
import Modernizr from 'modernizr'; // Import handled by modernizr-loader, so vendor.js must also have this line
import moment from 'moment';
import 'moment-timezone';
import Swiper from 'swiper';
import * as Sentry from '@sentry/browser';
Sentry.init({
  dsn: sentryDsn,
  integrations: [
    new Sentry.Integrations.Breadcrumbs({
      console: false,
    }),
  ],
});

// JQuery & Plugins
import 'jquery';
import 'jquery.dotdotdot';
import 'jquery.mmenu';

// Beamstyle - Jquery Plugins
import 'jquery.ajaxrest';
import 'jquery.ajaxrestform';
import 'jquery.infinitescroll';

var jlib = {
  myCore: {
    initialize: function() {
      jlib.myCore.dateTime.initialize();
    },
    dateTime: {
      initialize: function() {
        var dateTimeFormat = 'YYYY-MM-DD HH:mm:ss',
          browserDateTimeOnLoad = moment()
            .tz(myCore.dateTime.serverTimezone)
            .format(dateTimeFormat);

        myCore.dateTime.browserDateTimeClockDrift = moment(myCore.dateTime.serverDateTimeOnLoad, dateTimeFormat).diff(
          browserDateTimeOnLoad,
          'seconds',
        );

        function refreshServerDateTimeNow() {
          var browserDateTimeNow = moment()
              .tz(myCore.dateTime.serverTimezone)
              .format(dateTimeFormat),
            timeElapsedSinceLoad = moment(browserDateTimeNow, dateTimeFormat).diff(browserDateTimeOnLoad, 'seconds');

          myCore.dateTime.serverDateTimeNow = moment(myCore.dateTime.serverDateTimeOnLoad, dateTimeFormat)
            .add(timeElapsedSinceLoad, 'seconds')
            .format(dateTimeFormat);
          setTimeout(refreshServerDateTimeNow, 1000);
        }
        refreshServerDateTimeNow();
      },
      methods: {
        getServerTimezone: function() {
          return myCore.dateTime.serverTimezone;
        },
        getServerDateTimeOnLoad: function() {
          return myCore.dateTime.serverDateTimeOnLoad;
        },
        getServerDateTimeNow: function() {
          return myCore.dateTime.serverDateTimeNow;
        },
        getBrowserDateTimeClockDrift: function() {
          return myCore.dateTime.browserDateTimeClockDrift;
        },
      },
    },
  },
  helpers: {
    link: function(href, target) {
      target = target === undefined ? '_self' : target;
      window.open(href, target);
    },
    getFilenameWithoutExtension: function(filename) {
      return filename.replace(/\.[^/.]+$/, '');
    },
  },
  ajaxRest: {
    initialize: function() {
      // Form for "edit"
      $('.ajax-edit').ajaxRestForm({
        // Passing in the RESPONSES_BY_STATUS_CODES
        RESPONSES_BY_STATUS_CODES: RESPONSES_BY_STATUS_CODES,

        // Overriding some common callbacks.  We pass in "true" for recursive merge, and {} to ensure the objects we pass in do not get modified itself.
        callbacks: $.extend(true, {}, jlib.ajaxRest.commonCallbacks, {
          // Proper response callbacks
          success: function(jqXHR) {
            jlib.ajaxRest.commonCallbacks.success(jqXHR);
            var $form = $(this),
              successRedirectUrl = $form.data('success-redirect');
            jlib.helpers.link(successRedirectUrl);
          },
        }),
      });

      // Form for "create"
      $('.ajax-create').ajaxRestForm({
        // Passing in the RESPONSES_BY_STATUS_CODES
        RESPONSES_BY_STATUS_CODES: RESPONSES_BY_STATUS_CODES,

        // Overriding some common callbacks.  We pass in "true" for recursive merge, and {} to ensure the objects we pass in do not get modified itself.
        callbacks: $.extend(true, {}, jlib.ajaxRest.commonCallbacks, {
          // Proper response callbacks
          success: function(jqXHR) {
            jlib.ajaxRest.commonCallbacks.success(jqXHR);
            var $form = $(this),
              successRedirectUrl = $form.data('success-redirect');
            jlib.helpers.link(successRedirectUrl);
          },
        }),
      });
    },
    helpers: {
      displayErrors: function($form, errors) {
        $.each(errors, function(field, message) {
          var $field = $form.find('.field.' + CSS.escape(field, { isIdentifier: true })),
            $errorMessage = $field.find('.error-message');
          $errorMessage.text(message);
          $field.addClass('error');
        });
      },
    },
    commonCallbacks: {
      // Before AJAX request
      beforeSend: function(resolve, reject) {
        console.log('beforeSend');
        // $.LoadingOverlay('show');
        resolve();
      },

      // Connectivity error callbacks
      userAborted: function(jqXHR) {
        console.log('userAborted');
      },
      responseTimedOut: function(jqXHR) {
        console.log('responseTimedOut');
      },

      // Parser error callbacks
      parserError: function(jqXHR) {
        console.log('parserError');
      },

      // Proper response callbacks
      success: function(jqXHR) {
        console.log('success');
      },
      requestMalformed: function(jqXHR) {
        console.log('requestMalformed');
      },
      loginRequired: function(jqXHR) {
        console.log('loginRequired');
        var sessionTimedOutRedirectUrl =
          siteUrl + 'console/connect/login?redirectUrl=' + encodeURIComponent(currentRouteWithQueryString);
        jlib.helpers.link(sessionTimedOutRedirectUrl);
      },
      notEnoughAccess: function(jqXHR) {
        console.log('notEnoughAccess');
      },
      notFound: function(jqXHR) {
        console.log('notFound');
      },
      unsupportedFormat: function(jqXHR) {
        console.log('unsupportedFormat');
      },
      validationError: function(jqXHR) {
        var $form = $(this),
          data = jqXHR.responseJSON,
          errors = data.errors;
        jlib.ajaxRest.helpers.displayErrors($form, errors);
      },
      tooManyRequests: function(jqXHR) {
        console.log('tooManyRequests');
      },
      unexpectedError: function(jqXHR) {
        console.log('unexpectedError');
      },
      default: function(jqXHR) {
        console.log('default');
      },
    },
  },
};

$(document).ready(function(e) {
  jlib.myCore.initialize();
  jlib.ajaxRest.initialize();

  // Initialize multiline truncate (ellipsis / clamp) effect
  $('.multiline-truncate').each(function() {
    var $text = $(this),
      maximumLines = parseInt($text.data('maximum-lines')),
      lineHeight = parseInt($text.css('line-height'));

    if (!isNaN(maximumLines)) {
      $text.css('max-height', lineHeight * maximumLines);
      $text.css('overflow', 'hidden');
    }

    $text.dotdotdot({
      ellipsis: '...',
      watch: 'window',
    });
  });

  // Infinite Scroll
  $('.infinite-scroll').infiniteScroll({
    ajaxRestCallbacks: $.extend(true, {}, jlib.ajaxRest.commonCallbacks, {
      success: function(jqXHR) {
        jlib.ajaxRest.commonCallbacks.success(jqXHR);
        console.log('ajaxRestCallbacks.success', $(this), jqXHR);
      },
    }),
    callbackEntityAdded: function($entity) {
      console.log('callbackEntityAdded', $(this), $entity);
    },
  });

  // Block Carousel
  $('.carousel').each(function() {
    var $blockCarousel = $(this),
      $blockCarousel_swiper = $blockCarousel.find('.swiper-container'),
      $blockCarousel_pagination = $blockCarousel.find('.pagination'),
      $blockCarousel_navigation = $blockCarousel.find('.navigation'),
      $blockCarousel_navigation_buttonPrevious = $blockCarousel_navigation.find('.button-previous'),
      $blockCarousel_navigation_buttonNext = $blockCarousel_navigation.find('.button-next');

    var loop = $blockCarousel.data('loop'),
      autoplay = $blockCarousel.data('autoplay'),
      slidesPerView = $blockCarousel.data('slides-per-view'),
      slidesPerView_breakpoints = $blockCarousel.data('slides-per-view-breakpoints');

    var swiperOptions = {
      direction: 'horizontal',
      loop: loop !== undefined ? loop : false,
      slidesPerView: slidesPerView !== undefined ? parseInt(slidesPerView) : 1,
    };

    // If autoplay is defined and not false
    if (autoplay !== undefined && autoplay) {
      swiperOptions = $.extend(swiperOptions, {
        autoplay: {
          disableOnInteraction: true,
          delay: parseInt(autoplay),
        },
      });
    }

    // If there are navigation elements (< and >)
    if ($blockCarousel_navigation.length) {
      swiperOptions = $.extend(swiperOptions, {
        navigation: {
          prevEl: $blockCarousel_navigation_buttonPrevious,
          nextEl: $blockCarousel_navigation_buttonNext,
        },
      });
    }

    // If there is a pagination element (bullets)
    if ($blockCarousel_pagination.length) {
      swiperOptions = $.extend(swiperOptions, {
        pagination: {
          el: $blockCarousel_pagination,
          clickable: true,
        },
      });
    }

    var mySwiper = new Swiper($blockCarousel_swiper, swiperOptions);

    // Object that defines slidesPerView at mobile response media query breakpoints
    if (slidesPerView_breakpoints !== undefined) {
      var defaultSlidesPerView = slidesPerView;
      $(window)
        .resize(function() {
          var viewportWidth = window.innerWidth;
          mySwiper.params.slidesPerView = defaultSlidesPerView;
          slidesPerView_breakpoints.forEach(function(slidesPerView_breakpoint) {
            if (viewportWidth <= slidesPerView_breakpoint.breakpoint) {
              mySwiper.params.slidesPerView = slidesPerView_breakpoint.slidesPerView;
            }
          });
          mySwiper.update();
        })
        .trigger('resize');
    }
  });

  // Mobile Menu
  $('#mobile-nav').mmenu({
    navbar: {
      title: '',
    },
    extensions: ['position-front', 'position-right'],
  });
});
